
.cursor-pointer {
  cursor: pointer !important;
}

.vh-75 {
  height: 75vh !important;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.bg-light {
  background-color: #fdfdfd !important;
}

.gradient-blue {
  background: rgb(63, 146, 209);
  background: linear-gradient(90deg, rgba(63, 146, 209, 1) 0%, rgba(114, 181, 232, 1) 100%);
}

.text-blue-light {
  color: #DFF1FF;
}

.section-title {
  background: #EC1C23;
  background: -webkit-radial-gradient(circle farthest-side at top center, #EC1C23 0%, #900506 100%);
  background: -moz-radial-gradient(circle farthest-side at top center, #EC1C23 0%, #900506 100%);
  background: radial-gradient(circle farthest-side at top center, #EC1C23 0%, #900506 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rtl .dropdown-menu, .dropdown-menu#language-menu {
  right: 0;
  left: auto;
}

.rtl .dropdown-menu#language-menu {
  left: 0;
  right: auto
}

.banner-title {
  color: #9d1c1f;
  border-bottom: 3px solid #9d1c1f;
}

.feature:hover .text-feature, .feature:hover p {
  color: white;
}


/** shapes **/
.banner-isometric {
  max-width: 55%;
  height: auto;
}

.circle {
  background: #e3342f;
  border-radius: 50%;
  box-shadow: 0 3px 8px #aaa, inset 0 2px 3px #fff;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: .2;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: .2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}


/******************* Timeline Demo - 4 *****************/
.main-timeline4 {
  overflow: hidden;
  position: relative
}

.main-timeline4:before {
  content: "";
  width: 5px;
  height: 90%;
  background: #333;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%)
}

.main-timeline4 .timeline-content:before, .main-timeline4 .timeline:before {
  top: 50%;
  transform: translateY(-50%);
  content: ""
}

.main-timeline4 .timeline {
  width: 50%;
  padding-left: 100px;
  float: right;
  position: relative;
  min-height: 130px;
}

.main-timeline4 .timeline:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 5px solid #333;
  position: absolute;
  left: -10px
}

.main-timeline4 .timeline-content {
  display: block;
  padding-left: 150px;
  position: relative;
  min-height: 130px;
}

.main-timeline4 .timeline-content:before {
  width: 90px;
  height: 10px;
  border-top: 7px dotted #333;
  position: absolute;
  left: -92px
}

.main-timeline4 .year {
  display: inline-block;
  width: 120px;
  height: 120px;
  line-height: 100px;
  border-radius: 50%;
  border: 10px solid #f54957;
  font-size: 30px;
  color: #f54957;
  text-align: center;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, .4);
  position: absolute;
  top: 0;
  left: 0
}

.main-timeline4 .year:before {
  content: "";
  border-left: 20px solid #f54957;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  bottom: -13px;
  right: 0;
  transform: rotate(45deg)
}

.main-timeline4 .inner-content {
  padding: 20px 0
}

.main-timeline4 .title {
  font-size: 2rem;
  font-weight: 600;
  color: #f54957;
  text-transform: uppercase;
  margin: 0 0 5px;
  letter-spacing: 2px;
}

.main-timeline4 .description {
  font-size: 18px;
  color: #6f6f6f;
  margin: 0 0 5px
}

.main-timeline4 .timeline:nth-child(2n) {
  padding: 0 100px 0 0
}

.main-timeline4 .timeline:nth-child(2n) .timeline-content:before, .main-timeline4 .timeline:nth-child(2n) .year, .main-timeline4 .timeline:nth-child(2n):before {
  left: auto;
  right: -10px
}

.main-timeline4 .timeline:nth-child(2n) .timeline-content {
  padding: 0 150px 0 0;
  text-align: right;
}

.main-timeline4 .timeline:nth-child(2n) .timeline-content:before {
  right: -92px
}

.main-timeline4 .timeline:nth-child(2n) .year {
  right: 0
}

.main-timeline4 .timeline:nth-child(2n) .year:before {
  right: auto;
  left: 0;
  border-left: none;
  border-right: 20px solid #f54957;
  transform: rotate(-45deg)
}

.main-timeline4 .timeline:nth-child(2) {
  margin-top: 110px
}

.main-timeline4 .timeline:nth-child(odd) {
  margin: -110px 0 0
}

.main-timeline4 .timeline:nth-child(even) {
  margin-bottom: 80px
}

.main-timeline4 .timeline:first-child, .main-timeline4 .timeline:last-child:nth-child(even) {
  margin: 0
}

.main-timeline4 .timeline:nth-child(2n) .year {
  border-color: #1ebad0;
  color: #1ebad0
}

.main-timeline4 .timeline:nth-child(2n) .year:before {
  border-right-color: #1ebad0
}

.main-timeline4 .timeline:nth-child(2n) .title {
  color: #1ebad0
}

.main-timeline4 .timeline:nth-child(3n) .year {
  border-color: #7cba01;
  color: #7cba01
}

.main-timeline4 .timeline:nth-child(3n) .year:before {
  border-left-color: #7cba01;
  border-right-color: #7cba01
}

.main-timeline4 .timeline:nth-child(3n) .title {
  color: #7cba01
}

.main-timeline4 .timeline:nth-child(4) .year {
  border-color: #f8781f;
  color: #f8781f
}

.main-timeline4 .timeline:nth-child(4) .year:before {
  border-right-color: #f8781f
}

.main-timeline4 .timeline:nth-child(4) .title {
  color: #f8781f
}

@media only screen and (max-width: 1200px) {
  .main-timeline4 .year {
    top: 50%;
    transform: translateY(-50%)
  }
}

@media only screen and (max-width: 990px) {
  .main-timeline4 .timeline {
    padding-left: 75px
  }

  .main-timeline4 .timeline:nth-child(2n) {
    padding: 0 75px 0 0
  }

  .main-timeline4 .timeline-content {
    padding-left: 130px
  }

  .main-timeline4 .timeline:nth-child(2n) .timeline-content {
    padding: 0 130px 0 0
  }

  .main-timeline4 .timeline-content:before {
    width: 68px;
    left: -68px
  }

  .main-timeline4 .timeline:nth-child(2n) .timeline-content:before {
    right: -68px
  }
}

@media only screen and (max-width: 767px) {
  .main-timeline4 {
    overflow: visible
  }

  .main-timeline4:before {
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(0)
  }


  .main-timeline4 .timeline:nth-child(2n) .timeline-content {
    text-align: center;
  }

  .main-timeline4 .timeline:before, .main-timeline4 .timeline:nth-child(2n):before {
    top: 60px;
    left: -9px;
    transform: translateX(0)
  }

  .main-timeline4 .timeline, .main-timeline4 .timeline:nth-child(even), .main-timeline4 .timeline:nth-child(odd) {
    width: 100%;
    float: none;
    text-align: center;
    padding: 0;
    margin: 0 0 10px
  }

  .main-timeline4 .timeline-content, .main-timeline4 .timeline:nth-child(2n) .timeline-content {
    padding: 0
  }

  .main-timeline4 .timeline-content:before, .main-timeline4 .timeline:nth-child(2n) .timeline-content:before {
    display: none
  }

  .main-timeline4 .timeline:nth-child(2n) .year, .main-timeline4 .year {
    position: relative;
    transform: translateY(0)
  }

  .main-timeline4 .timeline:nth-child(2n) .year:before, .main-timeline4 .year:before {
    border: none;
    border-right: 20px solid #f54957;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    top: 50%;
    left: -23px;
    bottom: auto;
    right: auto;
    transform: rotate(0)
  }

  .main-timeline4 .timeline:nth-child(2n) .year:before {
    border-right-color: #1ebad0
  }

  .main-timeline4 .timeline:nth-child(3n) .year:before {
    border-right-color: #7cba01
  }

  .main-timeline4 .timeline:nth-child(4n) .year:before {
    border-right-color: #f8781f
  }

  .main-timeline4 .inner-content {
    padding: 10px
  }
}

.carousel-item {
  float: left;
  margin-left: auto;
  margin-right: -100%;
}

.carousel:not(#carouselExampleSlidesOnly):hover .carousel-control-next, .carousel:not(#carouselExampleSlidesOnly):hover .carousel-control-next {
  background: linear-gradient(90deg, rgba(112, 18, 20, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

.carousel:not(#carouselExampleSlidesOnly):hover .carousel-control-prev, .carousel:not(#carouselExampleSlidesOnly):hover .carousel-control-prev {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(112, 18, 20, 0) 100%);
}

html, body {
  height: auto;
  letter-spacing: 1px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .display-1, .display-2, .display-3, .display-4 {
  font-family: 'Arial';
  letter-spacing: 2px;
}

footer, .form-floating > label {
  font-size: 16px !important;
}

footer h1, footer h2, footer h3, footer h4, footer h5, footer h6, footer .h1, footer .h2, footer .h3, footer .h4, footer .h5, footer .h6, footer .display-1, footer .display-2, footer .display-3, footer .display-4, .font-babes {
  font-family: 'Arial';
  letter-spacing: 2px;
}

.nav-brand-bg {
  width: 100px;
  height: 60px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.4s ease;
}

.content {
  height: 100%;
  width: 100%;
}

@media (max-width: 570px) {
  .content {
    height: auto;
  }

  .nav-brand-bg {
    width: 70px;
    height: 45px;
  }

  .navbar-collapse {
    height: 100vh;
    width: 100vw;
    overflow: scroll;
  }

  .navbar-nav .dropdown-menu {
    width: 100%;
  }
}

@media (max-width: 1105px) {
  .navbar .bsn-history {
    display: none !important;
  }
}

@media (min-width: 1106px) {
  .navbar .bsn-history {
    left: 30%;
    right: 30%;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1650px;
  }
}

@media (max-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}

.navbar {
  border-bottom: 4px solid red;
}

.navbar .bsn-history {
  display: flex;
  height: 30px;
  position: absolute;
  width: auto;
  top: 12px;
  justify-content: center;
  transition: display 0.4s ease;
}

#navbar.fixed-top .bsn-history {
  display: none;
}

.section-fluid {
  max-width: 80%;
}

.dropdown-toggle:after {
  vertical-align: middle;
}

.footer p {
  margin-bottom: 0;
}

/* Flip Cards CSS */

.feature.card-container {
  display: flex;
  perspective: 700px;
  transition: all .3s ease;
}

.feature .card-flip {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas: "frontAndBack";
  transform-style: preserve-3d;
  transition: all 0.7s ease;
}

.feature .card-flip div {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.feature .card-flip .front {
  grid-area: frontAndBack;
  justify-content: center;
}

.feature .card-flip .back {
  grid-area: frontAndBack;
  justify-content: center;
  transform: rotateY(-180deg);
}

.feature.card-container:hover .card-flip {
  transform: rotateY(180deg);
  box-shadow: 1px 1px 25px 5px #ccc;
}

.text-black {
  color: #000 !important;
}

.vh-50 {
  min-height: 50vh !important;
}


@media (min-width: 768px) {
  .sticky-md-bottom {
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767px) {
  .sticky-md-bottom {
    position: relative;
  }
}
/** sliders **/
html,
body {
  height: 100%;
}

.carousel,
.item,
.active {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-caption {
  padding-bottom: 80px;
}

h2 {
  font-size: 60px;
}

p {
  padding: 10px
}

/* Background images are set within the HTML using inline CSS, not here */

.fill {
  width: 100%;
  height: calc(100vh - 70px);
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  opacity: 0.6;
}


/**
 * Button
 */
.btn-transparent {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.btn-transparent:hover {
  background-color: #fff;
}

.btn-rounded {
  border-radius: 70px;
}

.btn-large {
  padding: 11px 45px;
  font-size: 18px;
}

/**
 * Change animation duration
 */
.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}